// extracted by mini-css-extract-plugin
export var dNone = "team-module--d-none--bMj+k";
export var dBlock = "team-module--d-block--AJjhH";
export var dInline = "team-module--d-inline--BwzS-";
export var dFlex = "team-module--d-flex--CgYGf";
export var dMdNone = "team-module--d-md-none--86IPw";
export var dMdBlock = "team-module--d-md-block--3gycK";
export var dMdFlex = "team-module--d-md-flex--RcVtN";
export var dLgFlex = "team-module--d-lg-flex--2Beh1";
export var dLgNone = "team-module--d-lg-none--sUvyn";
export var dLgBlock = "team-module--d-lg-block--tgIP3";
export var dLgInline = "team-module--d-lg-inline--eIwgm";
export var teamSection = "team-module--teamSection--7Gkl1";
export var teamList = "team-module--teamList--YLtxl";
export var teamImg = "team-module--teamImg--DVfjW";
export var caption = "team-module--caption--s10yD";
export var name = "team-module--name--FGtOo";
export var description = "team-module--description--PbgSJ";
export var email = "team-module--email--I+n-m";
export var teamWrapper = "team-module--teamWrapper--y69i4";