import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import {
  caption,
  description,
  email,
  name,
  team,
  teamImg,
  teamList,
  teamSection,
  teamWrapper,
} from "./styles/team.module.scss";

import React from "react";

const Team = ({ data }) => {
  const employees = data.allStrapiEmployees?.nodes.map((employee) => {
    return employee.employee;
  });

  return (
    <section className={`${teamWrapper} fade-in`}>
      <div className={teamSection}>
        <div className={teamList}>
          {employees.map((employeeEntry) => (
            <div className={team} key={employeeEntry.id}>
              {employeeEntry.image !== null ? (
                <>
                  {employeeEntry.isCeo === true ? (
                    <Link to={`/about/`}>
                      <GatsbyImage
                        className={teamImg}
                        image={getImage(employeeEntry.image?.localFile)}
                        alt={employeeEntry.name}
                      ></GatsbyImage>
                    </Link>
                  ) : (
                    <GatsbyImage
                      className={teamImg}
                      image={getImage(employeeEntry.image?.localFile)}
                      alt={employeeEntry.name}
                    ></GatsbyImage>
                  )}
                </>
              ) : null}
              <div className={caption}>
                <p className={name}>{employeeEntry.name}</p>
                <p className={description}>{employeeEntry.description}</p>
                {employeeEntry.email !== null ? (
                  <p className={email}>
                    <a href={"mailto:" + employeeEntry.email}>
                      {employeeEntry.email}
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

Team.seoOptions = {
  title: "Team",
};
export default Team;

export const query = graphql`
  query {
    allStrapiEmployees(sort: { fields: id, order: ASC }) {
      nodes {
        employee {
          id
          isCeo
          description
          email
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    }
  }
`;
